<template>
  <div>
    <CDataTable
      v-if="items.length > 0"
      class="issue-table table-outline"
      hover
      :items="items"
      :fields="fields"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results available!',
        noItems: 'No issue found!',
      }"
      head-color="light"
      no-sorting
    >
      <td slot="restaurant" slot-scope="{item}">
        <div class="d-flex align-items-center">
          <div class="c-avatar mr-2">
            <CImg
              :src="item.restaurant_logo_path"
              class="c-avatar-img"
              style="width: 36px; height: 36px"
              placeholderColor="lightgray"
            />
          </div>
          <div>
            <CLink :to="{name: 'View Restaurant', params: { id: item.id }}" class="text-muted">{{ item.restaurant_name }}</CLink>
          </div>
        </div>
      </td>

      <td slot="location" slot-scope="{item}">
        <strong>{{item.restaurant_city }}</strong> <small>{{item.restaurant_postcode }}</small>
      </td>

      <td slot="temp_closed_untill_date" slot-scope="{item}">
        <div v-if="item.temp_closed_untill_date">
          <div class="small text-muted">{{ moment(item.temp_closed_untill_date).format('LL') }}</div>
          <strong>{{ moment(item.temp_closed_untill_date).fromNow() }}</strong>
        </div>
      </td>

      <td slot="fix" slot-scope="{item}" class="text-center">
        <CButton
          :to="{ name: 'Edit Restaurant', params: { id: item.id }, query: { tab: 7 } }"
          v-c-tooltip="{
            content: 'Edit Restaurant',
            placement: 'top',
          }"
          size="sm"
          color="info"
          variant="ghost"
          shape="pill"
        >
          <CIcon name="cil-align-left" />
        </CButton>

        <CButton
          :to="{ name: 'View Restaurant', params: { id: item.id } }"
          v-c-tooltip="{
            content: 'View Restaurant',
            placement: 'top',
          }"
          size="sm"
          color="primary"
          variant="ghost"
          shape="pill"
        >
          <CIcon name="cil-restaurant" />
        </CButton>
      </td>
    </CDataTable>

    <div v-else>
      <CSpinner v-if="loading" />
      <h6 v-else>No issue found!</h6>
    </div>
  </div>
</template>

<script>

export default {
  name: "IssueRestTempClosedExpired",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      fields: [
        { key: "restaurant" },
        { key: "location", _style: "width: 300px;" },
        { key: "temp_closed_untill_date", label: "Temporarily Closed Until and Including", _style: "width: 240px;" },
        { key: "fix", _style: "width: 140px; text-align: center;" },
      ],
    };
  },

  watch: {
    active: async function (val) {
      val && await this.getData();
    },
  },

  async mounted() {
    this.active && await this.getData();
  },

  methods: {
    async getData() {
      this.loading = true;
      const { data } = await this.$axios.get(this.$backend.ISSUE.GET, {
        params: {
          issue: "restaurants-temporarily-closed-expired",
        },
      })
      this.items = data.data;
      this.loading = false;
    },
  },
};
</script>

<style>
.issue-table table {
  margin-bottom: 0;
}

.issue-table table th, .issue-table table td {
  padding: 0.75rem;
  vertical-align: middle;
}
</style>
